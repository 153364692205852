<template>
    <BaseContainer>
        <span slot="principal">
            <v-content>
                <v-card elevation="0" class="mb-12">
                    <v-card-title class="justify-center">
                        <v-switch
                            dense
                            inset
                            true-value="ativo"
                            false-value="inativo"
                            hide-details
                            class="pb-2"
                            v-model="form.status"
                            :label="form.status == 'ativo' ? 'Disponível' : 'Indisponível'"
                        />
                        <v-spacer />
                        <span v-if="$vuetify.breakpoint.smAndUp">
                            {{ form.id ? 'Edição' : 'Cadastro' }} de Produto
                        </span>
                        <v-spacer />
                        <v-spacer />
                        <v-btn color="primary" icon style="rotate: 45deg;" @click="tooglePin">
                            <v-icon>mdi-pin{{ pin ? '' : '-off' }}</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text align="center" justify="center" class="mt-2">
                        <v-text-field
                            v-if="hasFiscalModule"
                            ref="codigo"
                            label="Código de Barras GTIN"
                            v-model="form.gtin"
                            @keypress.enter="$refs.nome.focus()"
                            v-mask="['##############']"
                            outlined
                            dense
                        />
                        <v-text-field
                            ref="nome"
                            label="Nome do Produto"
                            v-model="form.descricao"
                            @keypress.enter="$refs.grupo.focus()"
                            outlined
                            dense
                        />
                        <v-autocomplete
                            ref="grupo"
                            prepend-inner-icon="mdi-magnify"
                            v-model="form.grupo_id"
                            :items="grupos"
                            @keypress.enter="focusNext"
                            dense
                            clearable
                            item-text="descricao"
                            item-value="id"
                            label="Grupo (selecione ou cadastre um novo)"
                            outlined
                        >
                            <template #append-item>
                                <div
                                    style="font-size: 14px; font-weight: bold; padding: 14px; cursor: pointer;"
                                    @click="newGrupo"
                                >
                                    <v-icon>mdi-plus</v-icon> Cadastrar novo grupo
                                </div>
                            </template>
                            <template #append>
                                <v-btn
                                    @click="newGrupo"
                                    small
                                    outlined
                                >
                                    <v-icon>mdi-plus</v-icon> Novo
                                </v-btn>
                            </template>
                        </v-autocomplete>

                        <v-textarea
                            ref="detalhes"
                            clearable
                            clear-icon="mdi-backspace-outline"
                            v-model="form.detalhes"
                            label="Detalhes/Ingredientes"
                            outlined
                        />

                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    ref="valor"
                                    label="Preço"
                                    prefix="R$"
                                    v-model="form.valor"
                                    @keypress.enter="$refs.valorAntigo.focus()"
                                    placeholder="0.00"
                                    v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    ref="valorAntigo"
                                    label="Preço antigo"
                                    prefix="R$"
                                    v-model="form.valor_antigo"
                                    @keypress.enter="$refs.valorMin.focus()"
                                    placeholder="0.00"
                                    v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                    style="text-decoration: line-through;"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    ref="valorMin"
                                    label="Preço à partir de"
                                    prefix="R$"
                                    v-model="form.valor_minimo"
                                    @keypress.enter="$refs.custo.focus()"
                                    placeholder="0.00"
                                    v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    ref="custo"
                                    label="Custo"
                                    prefix="R$"
                                    v-model="form.custo"
                                    placeholder="0.00"
                                    v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                    outlined
                                    dense
                                    hide-details
                                />
                                <div class="caption" style="text-align: left;">{{ lucro }}</div>
                            </v-col>
                        </v-row>

                        <v-select
                            ref="categoriaImposto"
                            v-if="hasFiscalModule"
                            v-model="form.categoria_imposto_id"
                            :items="categoriasImposto"
                            :item-text="e => `${e.id} | ${e.nome}`"
                            item-value="id"
                            label="Categoria de imposto"
                            class="mt-6"
                            hide-details
                            outlined
                            dense
                        />
                        <v-switch
                            v-if="configuracoes.controle_estoque"
                            dense
                            inset
                            class="py-2"
                            hide-details
                            v-model="form.controle_estoque"
                            label="Controlar estoque"
                            outlined
                        />
                        <v-text-field
                            v-if="configuracoes.controle_estoque && form.controle_estoque"
                            label="Quantidade disponível"
                            v-model="form.estoque"
                            placeholder="0"
                            type="number"
                            outlined
                            dense
                        />

                        <v-switch
                            v-if="hasFidelidadeModule"
                            dense
                            inset
                            class="py-2"
                            hide-details
                            v-model="form.pontos_ativo"
                            label="Troca por pontos"
                            outlined
                        />
                        <v-text-field
                            v-if="form.pontos_ativo"
                            label="Multiplicador de pontos"
                            v-model="form.pontos_multiplicador"
                            hide-details
                            placeholder="0"
                            type="number"
                            outlined
                            dense
                        />
                        <div v-if="form.pontos_ativo" style="text-align: left;">
                            <span class="caption">
                                Os pontos necessários para o resgate do produto é o valor final do produto incluindo os complementos multiplicado pelo "Multiplicador de pontos" informado acima.
                                <br>Por exemplo, se o produto custar R$10,00 e o "Multiplicador de pontos" é {{ (form.pontos_multiplicador || 0) }}, serão necessários {{ (form.pontos_multiplicador * 10) || 0 }} pontos para resgatar o produto (10 x {{ form.pontos_multiplicador || 0  }} = {{ (form.pontos_multiplicador * 10) || 0 }}).
                            </span>
                        </div>

                        <h3 class="mt-4">Etapas do Produto</h3>
                        <v-autocomplete
                            prepend-inner-icon="mdi-magnify"
                            append-outer-icon="mdi-pencil"
                            @click:append-outer="onClickEditStep"
                            @change="onStepChange"
                            v-model="step"
                            :items="steps"
                            return-object
                            :item-text="e => `${e.id} - ${e.descricao}`"
                            item-value="id"
                            dense
                            label="Adicionar Etapas"
                            placeholder="Selecione"
                            outlined
                            hide-details
                        >
                            <template #append-item>
                                <div
                                    style="font-size: 14px; font-weight: bold; padding: 14px; cursor: pointer;"
                                    @click="newStep"
                                >
                                    <v-icon>mdi-plus</v-icon> Cadastrar nova etapa
                                </div>
                            </template>
                            <template #append>
                                <v-btn
                                    @click="newStep"
                                    small
                                    outlined
                                >
                                    <v-icon>mdi-plus</v-icon> Novo
                                </v-btn>
                            </template>
                        </v-autocomplete>

                        <v-divider class="my-1"/>

                        <draggable v-model="formSteps" class="mb-12">
                            <div v-for="(step, index) in formSteps" :key="index">
                                <v-row class="py-3">
                                    <v-col style="max-width: 30px;" class="pt-4">
                                        <v-icon style="cursor: move;">mdi-drag-vertical</v-icon>
                                    </v-col>
                                    <v-col class="pt-4 normal-text">
                                        {{ step.id }} - {{ step.descricao }}
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            @change="onChangeQtd($event, 'min', index)"
                                            v-model="step.min"
                                            style="width: 80px;"
                                            label="Min"
                                            type="number"
                                            class="pt-3"
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            @change="onChangeQtd($event, 'max', index)"
                                            v-model="step.max"
                                            style="width: 80px;"
                                            label="Max"
                                            type="number"
                                            class="pt-3"
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                    <v-col class="pt-2">
                                        <v-btn @click.stop="removeStep(index)" color="red" icon>
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-divider class="mt-1 mb-1"/>
                            </div>
                        </draggable>

                        <div style="border: solid 1px #999; border-radius: 5px;">
                            <b>Imagem</b>
                            <ImageInput ref="imagem" v-model="form.imagem" />
                        </div>

                        <h3 class="mt-8">Disponibilidade</h3>

                        <v-radio-group
                            v-model="form.disponibilidade.sempre_disponivel"
                            row
                            hide-details
                            style="margin-top: 5px;"
                        >
                            <v-radio
                                label="Sempre disponível"
                                :value="true"
                            />
                            <v-radio
                                label="Dias e horários específicos"
                                :value="false"
                            />
                        </v-radio-group>

                        <div v-if="!form.disponibilidade.sempre_disponivel" class="mt-4">
                            <v-row align="center" v-for="d in form.disponibilidade.programacao" :key="d.diaSemana">
                                <v-switch
                                    dense
                                    inset
                                    hide-details
                                    class="mr-2"
                                    style="width: 95px;"
                                    v-model="d.aberto"
                                    :label="d.diaSemana.substr(0, 3)"
                                />
                                <v-text-field
                                    v-model="d.horario"
                                    hide-details
                                    style="max-width: 105px; border: 1px solid #ddd; border-radius: 5px; padding-left: 5px;"
                                    v-mask="['##:## - ##:##']"
                                    placeholder="00:00 - 00:00"
                                    :disabled="!d.aberto"
                                />
                                <v-btn
                                    icon
                                    small
                                    color="primary"
                                    v-if="!d.segundoHorarioAtivo"
                                    @click="toogleSegundoHorario(d.diaSemana)"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-text-field
                                    v-else
                                    v-model="d.segundoHorario"
                                    hide-details
                                    style="max-width: 130px; border: 1px solid #ddd; border-radius: 5px; padding-left: 5px;"
                                    v-mask="['##:## - ##:##']"
                                    placeholder="00:00 - 00:00"
                                    :disabled="!d.aberto"
                                >
                                    <template v-slot:append-outer>
                                        <v-btn icon x-small @click="toogleSegundoHorario(d.diaSemana)">
                                            <v-icon color="red">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </v-row>
                        </div>
                        
                        <div class="mb-12"/>
                    </v-card-text>
                </v-card>
                <v-footer fixed class="pa-4">
                    <v-btn link large color="warning" text to="/produtos">Cancelar</v-btn>
                    <v-spacer />
                    <v-btn color="success" large @click="salvar" :loading="loading">
                        <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                    </v-btn>
                </v-footer>

                <Steps
                    :visible="complementosVisible"
                    @close="onStepClose"
                    @save="fillSteps"
                    @delete="deleteStep"
                />

                <CadastroGrupo
                    :visible="grupoVisible"
                    :formData="formGrupo"
                    @onCadastro="onCadastroGrupo"
                    @close="closeGrupo"
                />
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import ImageInput from '@/components/ImageInput';
import Steps from './Steps';
import CadastroGrupo from './Grupo';
import draggable from 'vuedraggable';
import { clone } from '@/utils/utils';
import { getTimeErrors } from '@/utils/validate';
import { mapState, mapMutations } from 'vuex';
import { hasModule } from '@/utils/module';

export default {
    name: 'Produto',

    components: {
        BaseContainer,
        ImageInput,
        Steps,
        CadastroGrupo,
        draggable,
    },

    data: () => ({
        innitForm: {
            id: '',
            gtin: null,
            valor: '',
            valor_antigo: '',
            valor_minimo: '',
            custo: '',
            detalhes: '',
            grupo_id: '',
            descricao: '',
            imagem: null,
            passos: [],
            ativo: true,
            status: 'ativo',
            controle_estoque: false,
            estoque: 0,
            pontos_ativo: false,
            pontos_multiplicador: false,
            disponibilidade: {
                sempre_disponivel: true,
                programacao: [
                    {isoWeekday: 1, diaSemana: 'SEGUNDA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 2, diaSemana: 'TERCA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 3, diaSemana: 'QUARTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 4, diaSemana: 'QUINTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 5, diaSemana: 'SEXTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 6, diaSemana: 'SÁBADO', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 7, diaSemana: 'DOMINGO', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                ],
            },
        },
        form: { disponibilidade: {} },
        formSteps: [],
        formGrupo: {},
        pin: false,
        loading: false,
        grupoVisible: false,
        complementosVisible: false,
        step: [],
        steps: [],
        grupos: [],
        categoriasImposto: [],
        dados: [],
    }),

    mounted() {
        this.form = clone(this.innitForm);
        this.pin = localStorage.getItem('pin-cadastro-produtos') === 'true';
        let dados = this.$store.getters.getData;

        if (dados?.id) {
            this.getById(dados.id);
            this.$store.commit('setData', {});
        }

        this.fillSteps();
        this.fillGrupos();
        this.fillCategoriasImposto();

        setTimeout(() => this.$refs.nome.focus(), 100);
    },

    computed: {
        ...mapState([
            'configuracoes',
        ]),

        orderedSteps() {
            return this.formSteps?.map((items, index) => ({...items, ...{ordem: index + 1}})) || [];
        },

        getGrupos() {
            return this.grupos;
        },

        lucro() {
            if (!this.form.valor || !this.form.custo) {
                return '';
            }
            const custo = (this.form.valor - this.form.custo).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            });
            return `LUCRO (PREÇO - CUSTO) = ${custo}`;
        },

        hasFiscalModule() {
            return hasModule('FISCAL');
        },

        hasFidelidadeModule() {
            return hasModule('FIDELIDADE');
        },

        cssVars() {
            return {
                '--bg-color': '#3874d0',
                '--color': '#fff',
            };
        },
    },

    methods: {
        ...mapMutations({
            setDashboardInitialized: 'setDashboardInitialized',
        }),

        hasSteps(steps) {
            return steps?.length;
        },

        fillSteps() {
            this.steps = [];
            this.$http.get('passos').then(resp => {
                this.steps = resp.data.data;
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },

        getById(id) {
            this.$root.$emit('loading', true);
            this.$http.get(`produtos/${id}`).then(({ data }) => {
                this.form = data;
                this.formSteps = data.passos;

                if (this.$route.query.duplicar) {
                    this.form.id = null;
                    this.form.ordem = null;
                    this.form.descricao += ' - CÓPIA';
                    this.formSteps.map(e => (e.produto_passo_id = null));
                    this.notify('Produto duplicado');
                }
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            })
            .finally(() => (this.$root.$emit('loading', false)));
        },

        fillGrupos(grupo = null) {
            this.grupos = [];
            this.$http.get('grupos').then(resp => {
                this.grupos = resp.data.data;
                grupo && (this.form.grupo_id = grupo);
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },

        async fillCategoriasImposto() {
            if (!this.hasFiscalModule) {
                return;
            }
            const { data } = await this.$http.get('categorias-imposto');
            this.categoriasImposto = data.data;
        },

        newStep() {
            this.complementosVisible = true;
        },

        onClickEditStep() {
            this.complementosVisible = true;
        },

        onStepClose() {
            this.complementosVisible = false;
        },

        deleteStep(id) {
            this.steps = this.steps.filter(e => e.id !== id);
            this.formSteps = this.formSteps.filter(e => e.id !== id);
        },

        onChangeQtd(value, field, index) {
            this.formSteps[index][field] = value;
        },

        onStepChange(passo) {
            if (!this.formSteps) {
                this.formSteps = [];
            }

            this.formSteps.push({
                produto_passo_id: null,
                ordem: this.formSteps.length + 1,
                id: passo.id,
                descricao: passo.descricao,
                max: '',
                min: '',
            });

            this.step = [];
        },

        async removeStep(index) {
            if (!await this.$root.$confirm('Confirmação', 'Deseja desvincular a etapa?', { color: 'red darken-1' })) {
                return;
            }
            this.formSteps.splice(index, 1);
        },

        newGrupo() {
            this.formGrupo = { ativo: true, status: 'ativo' };
            this.grupoVisible = true;
        },

        onCadastroGrupo(grupo = null) {
            this.grupoVisible = false;
            this.fillGrupos(grupo);
        },

        closeGrupo() {
            this.grupoVisible = false;
        },

        tooglePin() {
            this.pin = !this.pin;
            localStorage.setItem('pin-cadastro-produtos', this.pin);
        },

        salvar() {
            const id = this.form.id;

            this.form.passos = this.orderedSteps;

            if (!this.form.descricao) {
                this.notify('Informe o nome do produto', 'warning');
                return;
            }

            if (!this.form.grupo_id) {
                this.notify('Informe o grupo', 'warning');
                return;
            }

            if (this.form.valor === '') {
                this.notify('Informe o valor', 'warning');
                return;
            }

            if (this.form.valor_antigo && +this.form.valor > +this.form.valor_antigo) {
                this.notify('Preço antigo não pode ser maior que o preço atual', 'warning');
                return;
            }

            if (this.form.controle_estoque && +this.form.estoque < 0) {
                this.notify('Estoque não pode ser nagativo', 'warning');
                return;
            }

            const timeError = getTimeErrors(this.form.disponibilidade.programacao);

            if (timeError) {
                this.notify(timeError, 'warning');
                return;
            }

            const invalidStep = this.form.passos.find(p => p.min === '' || p.max === '');

            if (invalidStep) {
                this.notify(`Informe o valor Min e Max da etapa "${invalidStep.descricao}"`, 'warning');
                return;
            }

            if (id) {
                this.loading = true;
                this.$http.put(`produtos/${id}`, this.form).then(() => {
                    this.pin ? this.formReset() : this.$router.push('/produtos');
                    this.notify('Atualizado com sucesso!');
                    this.setDashboardInitialized(false);
                }).catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('produtos', this.form).then(() => {
                this.pin ? this.formReset() : this.$router.push('/produtos');
                this.notify('Salvo com sucesso!');
                this.setDashboardInitialized(false);
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },

        focusNext() {
            this.$refs.grupo.blur();
            this.$refs.detalhes.focus();
        },

        formReset() {
            this.form = clone(this.innitForm);
            this.formSteps = [];
            this.formGrupo = {};
        },

        toogleSegundoHorario(diaSemana) {
            const programacao = this.form.disponibilidade.programacao.find(f => f.diaSemana == diaSemana);
            programacao.segundoHorarioAtivo = !programacao.segundoHorarioAtivo;
            this.$forceUpdate();
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}

.col {
    padding: 0;
}

.normal-text {
    color: black;
}

.v-btn--active {
    background-color: var(--bg-color) !important;
    color: var(--color) !important;
}
</style>
